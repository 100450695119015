<template>
    <div>

        <router-view v-slot="{ Component }">
            <keep-alive :include="keepAliveArr">
                <component :is="Component" />
            </keep-alive>
        </router-view>

        <van-tabbar v-model="active" fixed safe-area-inset-bottom placeholder>
            <van-tabbar-item icon="home-o" to="/home">{{ items[0] }}</van-tabbar-item>
            <van-tabbar-item icon="orders-o" to="/category">{{ items[1] }}</van-tabbar-item>
            <van-tabbar-item icon="eye-o" to="/search">{{ items[2] }}</van-tabbar-item>
            <van-tabbar-item icon="friends-o" to="/my">{{ items[3] }}</van-tabbar-item>
        </van-tabbar>

    </div>
</template>

<script>

export default {
    name: 'MainPage',
    components: {
    },
    setup() {
    },
    computed: {
        keepAliveArr() {
            return this.$store.state.keepAliveArr
        },
        active: {
            get() {
                return this.$store.state.mainTabBarActive
            },
            set(val) {
                this.$store.commit('updateMainTabBarActive', val)
            }
        }
    },
    data() {
        return {
            items: [ '首页', '分类', '发现', '我的' ],
        }
    }
}
</script>

<style lang="stylus" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>